












































































































































































import { Delivery, Nav, Order, OrderState } from "@/graphql/client";
import { Component, Emit, Mixins } from "vue-property-decorator";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import orderService from "@/service/orderService";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class NavViewDialog extends Mixins(MixinFormatter) {
  //---------------------------
  // data
  //---------------------------
  items: Nav[] = [];
  order: Order = orderService.defaultOrder;

  /**
   * 配送完了済にするNav
   */
  navsToDeliveryCompleted: Nav[] = [];

  isOpen = false;
  isProgressing = false;

  isLoading = true;

  isOpenConfirm = false;

  headers = [
    {
      text: "配送先連番",
      value: "id",
      width: "100px",
    },
    {
      text: "氏名",
      value: "deliveryName",
      width: "150px",
    },
    {
      text: "郵便番号",
      value: "deliveryPostalCode",
      width: "100px",
    },
    {
      text: "住所",
      value: "deliveryAddress",
      width: "380px",
    },
    {
      text: "お届け商品",
      value: "item",
    },
  ];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 注文ステータスが「配送伝票取り込み済」の場合はtrueを返します
   */
  get isImportedDeliverySlip() {
    if ( this.order.status && (this.order.status === OrderState.ImportedDeliverySlip || this.order.status === OrderState.PerticalDeliveryCompleted) ) {
      return true;
    }
    return false;
  };
  //---------------------------
  // methods
  //---------------------------
  isOldNav(nav: Nav) {
    if (nav && nav.deliveries) {
      const hasOldDelivery = nav.deliveries.some((deliv) => {
        if (deliv && !Object.prototype.hasOwnProperty.call(deliv, 'deliveryCompleted')) {
          return true;
        }
      });
      return hasOldDelivery;
    }
    return false;
  }
  /**
   * 指定したNAVにお届けが含まれている場合はtrueを返します
   * @param nav
   */
  hasDeliveries(nav: Nav) {
    if (nav && nav.deliveries && nav.deliveries.length > 0) {
      return true;
    }
    return false;
  }
  /**
   * すべての配送が「配送完了」の場合はtrueを返します
   * @param nav
   */
  isAllNavDeliveriesCompleted(nav: Nav) {
    if (nav && nav.deliveries) {
      const hasNotDeliveryCompleted = nav.deliveries.some((deliv) => {
        if (deliv && deliv.deliveryCompleted === false) {
          return true;
        }
      });
      return !hasNotDeliveryCompleted;
    }
    return false;
  };

  /**
   * すべてのNavの配送が配送完了になっている場合は true
   */
  isAllDeliveriesCompleted(navs: Nav[]) {
    if (navs) {
      const hasNotDeliveryCompleted = navs.some((nav) => {
        if (this.isAllNavDeliveriesCompleted(nav) === false) {
          return true;
        }
      });
      return !hasNotDeliveryCompleted;
    }
    return false;
  };

  private async fetchData(input: Order): Promise<void> {
    this.isLoading = true;
    this.items = [];
    this.navsToDeliveryCompleted = [];
    this.order = orderService.defaultOrder;

    //表示データ取得
    if (input.guid) {
      const order = await orderService.getOrder(input.guid);
      if (order) {
        this.order = order;
      }

      await orderService.getNavs(input.guid).then((items) => {
        if (items) {
          this.items = items;
        }
      });
    }
    this.isLoading = false;
  }

  /**
   * ダイアログを表示します.
   */
  public open(item: Order): void {
    this.fetchData(item);

    this.navsToDeliveryCompleted = [];
    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.navsToDeliveryCompleted = [];
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 配送完了にする対象の送り状IDを抽出します。
   */
  get deliveryIdsToCompleted() {
    const ids: string[] = [];
    if (this.navsToDeliveryCompleted && this.navsToDeliveryCompleted.length > 0) {
      // 選択中のNavに含まれる送り状のIDを取得
      const res = this.navsToDeliveryCompleted.forEach((nav) => {
        if (nav && nav.deliveries && nav.deliveries.length > 0 ) {
          nav.deliveries.forEach((deliv) => {
            if (deliv && deliv.id) {
              ids.push(deliv.id);
            }
          })
        }
      });
    }
    return ids;
  }

  /**
   * 選択中のNAVに含まれる送り状を「配送完了」の状態にします
   */
  public async doCompleteDelivery(): Promise<void> {
    if (this.deliveryIdsToCompleted && this.deliveryIdsToCompleted.length > 0) {
      // 対象のDeliveryIdを取得
      const count = this.deliveryIdsToCompleted.length;
      this.isProgressing = true;
      // お届を「配送完了」にする
      await orderService.completeDeliveriesByIds(this.deliveryIdsToCompleted);
      // 再取得
      if (this.order.guid) {
        const navs = await orderService.getNavs(this.order.guid);
        if (navs && this.isAllDeliveriesCompleted(navs) === true) {
          // すべての配送が完了になっていたら、注文を「配送完了」にする
          await orderService.transitionToDeliveryCompleted(this.order);
        }
      }
      this.notifyUpdateOrderStatus();
      // 再取得
      await this.fetchData(this.order);
      this.isProgressing = false;
    }
    this.isOpenConfirm = false;
  }
  /**
   * 注文ステータス変更
   */
  @Emit("onUpdateOrderStatus")
  public notifyUpdateOrderStatus(): boolean {
    return true;
  }
}
