import { GuidInput, Item, ItemFilter } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";
import { toGraphQLResponse } from "@/utils";
import moment from "moment";

/**
 * カタログマスタに関する機能を提供します.
 */
class ItemService {
  /**
   * 初期状態の Item
   */
  public get defaultItem(): Item {
    return {
      guid: "",
      webCode: "",
      bundledItems: [],
      bundledWithItems: [],
      purposes: [],
      categories: [],
      brand: undefined,
      character: undefined,
      catalogs: [],
      code: "",
      name: "",
      displayName: "",
      daishinCode: "",
      makerCode: "",
      sellerCode: "",
      janCode: "",
      catalogPage: "",
      keyword: "",
      catchCopy: "",
      content: "",
      comment: "",
      description: "",
      memo: "",
      imageUrl01: "",
      imageUrl02: "",
      imageUrl03: "",
      imageUrl04: "",
      imageUrl05: "",
      imageUrl06: "",
      imageUrl07: "",
      imageUrl08: "",
      minimumOrderQuantity: 0,
      stockQuantity: -1,
      displayOrder: 0,
      taxType: "NORMAL",
      price: 0,
      postage: 0,
      sectionId: "",
      onlyForCatalogOrder: false,
      isFresh: false,
      sendDirectly: false,
      noshiSize: "",
      forPrivateUse: false,
      isPostageFree: false,
      canPickupAtStore: false,
      hasPersonalizedProperty: false,
      cannotBeAddedNoshi: false,
      cannotBeAddedWarppingPaper: false,
      cannotBeAddedMessageCard: false,
      isAvailable: true,
      publicationDate: moment().format(),
      publicationEndDate: moment().add(2, "years").format(),
      sendday: null,
      updatedAt: "",
      createdAt: "",
      onDiscount: false,
      onlyForMember: false,
      affiliatePrice: 0,
      specialMemberPrice: 0,
      specialPrice: 0,
      catalogUrl: "",
      catalogCodes: [],
    } as Item;
  }

  /**
   * すべての商品を取得して返します.
   * @returns すべての商品
   */
  public async allItems() {
    const response = await sdk.items();
    if (response?.items) {
      return response.items as Item[];
    }
    return [] as Item[];
  }

  /**
   * 検索条件に一致する商品を取得して返します.
   * @returns すべての商品
   */
  public async searchItems(
    condition: ItemFilter,
    skip: number | undefined = undefined,
    take: number | undefined = undefined
  ) {
    const response = await sdk.items({
      filter: condition,
      skip: skip,
      take: take,
    });
    if (response?.items) {
      return response.items as Item[];
    }
    return [] as Item[];
  }
  public async countItems(
    filter: ItemFilter | undefined = undefined,
  ) {
    const response = await sdk.countItems({
      filter: filter,
    });
    return response.countItems as number;
  }

  /**
   * 指定したGUIDに一致する商品を取得して返します.
   * @param guid 商品のGUID
   * @returns 商品
   */
  public async getItem(guid: string) {
    const response = await sdk.item({
      itemKey: { guid: guid },
    });
    return response.item as Item;
  }

  /**
   * 商品を登録します.
   * @param input 商品の登録情報
   * @returns 登録完了後の商品
   */
  public async createItem(input: Item) {
    const purposes: GuidInput[] = [];
    let character: GuidInput | undefined = undefined;
    if (input.character) {
      character = { guid: input.character.guid };
    }
    if (input.purposes) {
      input.purposes.forEach((item) => {
        if (item && item.guid) purposes.push({ guid: item.guid });
      });
    }
    const options: GuidInput[] = [];
    if (input.options) {
      input.options.forEach((item) => {
        if (item) options.push({ guid: item.guid });
      });
    }
    const categories: GuidInput[] = [];
    if (input.categories) {
      input.categories.forEach((item) => {
        if (item) categories.push({ guid: item.guid });
      });
    }
    const brands: GuidInput[] = [];
    if (input.brands) {
      input.brands.forEach((item) => {
        if (item) brands.push({ guid: item.guid });
      });
    }
    const catalogs: GuidInput[] = [];
    if (input.catalogs) {
      input.catalogs.forEach((item) => {
        if (item) catalogs.push({ guid: item.guid });
      });
    }
    const bundledItems: GuidInput[] = [];
    if (input.bundledItems) {
      input.bundledItems.forEach((item) => {
        if (item) bundledItems.push({ guid: item.guid });
      });
    }
    if (input.sendday?.toString() == "") {
      input.sendday = null;
    }
    if (input.stockQuantity?.toString() == "") {
      input.stockQuantity = null;
    }
    try {
      if (!input.webCode || !input.daishinCode) {
        throw new Error("webCode and daishinCode is required.");
      }
      const response = await sdk.createItem({
        createItemInput: {
          webCode: input.webCode,
          name: input.name,
          displayName: input.displayName,
          sectionId: input.sectionId,
          code: input.code,
          janCode: input.janCode,
          daishinCode: input.daishinCode,
          catalogPage: input.catalogPage,
          onlyForCatalogOrder: input.onlyForCatalogOrder,
          keyword: input.keyword,
          catchCopy: input.catchCopy,
          comment: input.comment,
          isFresh: input.isFresh,
          makerCode: input.makerCode,
          sellerCode: input.sellerCode,
          memo: input.memo,
          publicationDate: input.publicationDate,
          publicationEndDate: input.publicationEndDate,
          minimumOrderQuantity: input.minimumOrderQuantity,
          stockQuantity: input.stockQuantity,
          sendDirectly: input.sendDirectly,
          noshiSize: input.noshiSize,
          forPrivateUse: input.forPrivateUse,
          taxType: input.taxType,
          price: input.price ? input.price : 0,
          content: input.content,
          postage: input.postage ? input.postage : 0,
          isPostageFree: input.isPostageFree,
          canPickupAtStore: input.canPickupAtStore,
          isAvailable: input.isAvailable,
          displayOrder: input.displayOrder ? input.displayOrder : 0,
          hasPersonalizedProperty: input.hasPersonalizedProperty,
          cannotBeAddedMessageCard: input.cannotBeAddedMessageCard,
          cannotBeAddedNoshi: input.cannotBeAddedNoshi,
          cannotBeAddedWarppingPaper: input.cannotBeAddedWarppingPaper,
          description: input.description,
          imageUrl01: input.imageUrl01,
          imageUrl02: input.imageUrl02,
          imageUrl03: input.imageUrl03,
          imageUrl04: input.imageUrl04,
          imageUrl05: input.imageUrl05,
          imageUrl06: input.imageUrl06,
          imageUrl07: input.imageUrl07,
          imageUrl08: input.imageUrl08,
          options: options,
          purposes: purposes,
          character: character,
          brands: brands,
          categories: categories,
          catalogs: catalogs,
          bundledItems: bundledItems,
          sendday: input.sendday,
          originalPrice: input.originalPrice,
          specialPrice: input.specialPrice,
          affiliatePrice: input.affiliatePrice,
          specialMemberPrice: input.specialMemberPrice,
          onlyForMember: input.onlyForMember,
          onDiscount: input.onDiscount,
          catalogUrl: input.catalogUrl,
          catalogCodes: input.catalogCodes,
        },
      });

      if (response?.createItem) {
        return response.createItem;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 商品を更新します.
   * @param input 商品の更新情報
   * @returns 更新完了後の商品
   */
  public async updateItem(input: Item) {
    let character: GuidInput | undefined = undefined;
    if (input.character) {
      character = { guid: input.character.guid };
    }
    const purposes: GuidInput[] = [];
    if (input.purposes) {
      input.purposes.forEach((item) => {
        if (item && item.guid) purposes.push({ guid: item.guid });
      });
    }
    const options: GuidInput[] = [];
    if (input.options) {
      input.options.forEach((item) => {
        if (item) options.push({ guid: item.guid });
      });
    }
    const categories: GuidInput[] = [];
    if (input.categories) {
      input.categories.forEach((item) => {
        if (item) categories.push({ guid: item.guid });
      });
    }
    const brands: GuidInput[] = [];
    if (input.brands) {
      input.brands.forEach((item) => {
        if (item) brands.push({ guid: item.guid });
      });
    }
    const catalogs: GuidInput[] = [];
    if (input.catalogs) {
      input.catalogs.forEach((item) => {
        if (item) catalogs.push({ guid: item.guid });
      });
    }
    const bundledItems: GuidInput[] = [];
    if (input.bundledItems) {
      input.bundledItems.forEach((item) => {
        if (item) bundledItems.push({ guid: item.guid });
      });
    }
    if (input.sendday?.toString() == "") {
      input.sendday = null;
    }
    if (input.stockQuantity?.toString() == "") {
      input.stockQuantity = null;
    }
    try {
      const response = await sdk.updateItem({
        updateItemInput: {
          guid: input.guid,
          webCode: input.webCode,
          name: input.name,
          displayName: input.displayName,
          sectionId: input.sectionId,
          code: input.code,
          janCode: input.janCode,
          daishinCode: input.daishinCode,
          catalogPage: input.catalogPage,
          onlyForCatalogOrder: input.onlyForCatalogOrder,
          keyword: input.keyword,
          catchCopy: input.catchCopy,
          comment: input.comment,
          isFresh: input.isFresh,
          makerCode: input.makerCode,
          sellerCode: input.sellerCode,
          memo: input.memo,
          publicationDate: input.publicationDate,
          publicationEndDate: input.publicationEndDate,
          minimumOrderQuantity: input.minimumOrderQuantity,
          stockQuantity: input.stockQuantity,
          sendDirectly: input.sendDirectly,
          noshiSize: input.noshiSize,
          forPrivateUse: input.forPrivateUse,
          taxType: input.taxType,
          price: input.price ? input.price : 0,
          content: input.content,
          postage: input.postage ? input.postage : 0,
          isPostageFree: input.isPostageFree,
          canPickupAtStore: input.canPickupAtStore,
          displayOrder: input.displayOrder ? input.displayOrder : 0,
          hasPersonalizedProperty: input.hasPersonalizedProperty,
          cannotBeAddedMessageCard: input.cannotBeAddedMessageCard,
          cannotBeAddedNoshi: input.cannotBeAddedNoshi,
          cannotBeAddedWarppingPaper: input.cannotBeAddedWarppingPaper,
          description: input.description,
          imageUrl01: input.imageUrl01,
          imageUrl02: input.imageUrl02,
          imageUrl03: input.imageUrl03,
          imageUrl04: input.imageUrl04,
          imageUrl05: input.imageUrl05,
          imageUrl06: input.imageUrl06,
          imageUrl07: input.imageUrl07,
          imageUrl08: input.imageUrl08,
          options: options,
          purposes: purposes,
          character: character,
          brands: brands,
          categories: categories,
          catalogs: catalogs,
          bundledItems: bundledItems,
          sendday: input.sendday,
          originalPrice: input.originalPrice,
          specialPrice: input.specialPrice,
          affiliatePrice: input.affiliatePrice,
          specialMemberPrice: input.specialMemberPrice,
          onlyForMember: input.onlyForMember,
          isAvailable: input.isAvailable,
          onDiscount: input.onDiscount,
          catalogUrl: input.catalogUrl,
          catalogCodes: input.catalogCodes,
        },
      });

      if (response?.updateItem) {
        return response.updateItem;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * バンドル可能な商品を更新します.
   * @param input 商品の更新情報
   * @returns 更新完了後の商品
   */
  public async updateBundledItems(input: Item) {
    const bundledItems: GuidInput[] = [];
    if (input.bundledItems) {
      input.bundledItems.forEach((item) => {
        if (item) bundledItems.push({ guid: item.guid });
      });
    }
    try {
      const response = await sdk.updateBundledItems({
        updateBundledItemsInput: {
          guid: input.guid,
          bundledItems: bundledItems,
        },
      });

      if (response?.updateBundledItems) {
        return response.updateBundledItems;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 商品を削除します.
   * @param input 商品の削除情報
   * @returns 削除したの商品
   */
  public async deleteItem(input: Item) {
    try {
      const response = await sdk.deleteItem({
        deleteItemInput: {
          guid: input.guid,
        },
      });
      if (response?.deleteItem) {
        return response.deleteItem;
      }
    } catch (err) {
      const response = toGraphQLResponse(err);
      if (response && response.errors) {
        throw new Error(response.errors[0].message);
      }
      throw err;
    }
  }

  /**
   * 商品情報のインポートを実行します（S3にアップロードされたExcelをインポート）
   * @returns インポートした商品情報の一覧
   */
  public async launchImportItems() {
    try {
      const response = await sdk.launchImportItems();
      if (response?.launchImportItems) {
        return response.launchImportItems;
      }
    } catch (err) {
      const response = toGraphQLResponse(err);
      if (response && response.errors) {
        throw new Error(response.errors[0].message);
      }
      throw err;
    }
  }

  /**
   * 商品情報のエクスポートを実行します（エクスポートファイルはS3に保存されます）
   * @returns エクスポートファイルのURL
   */
  public async launchExportItems() {
    try {
      const data = await sdk.launchExportItems();

      if (data?.launchExportItems) {
        return data.launchExportItems;
      }
    } catch (err) {
      const response = toGraphQLResponse(err);
      if (response && response.errors) {
        throw new Error(response.errors[0].message);
      }
      throw err;
    }
  }
}

export default new ItemService();
