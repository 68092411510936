




































































import { Component, Emit, Vue } from "vue-property-decorator";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import parse from "csv-parse/lib/sync";
import iconv from "iconv-lite";
import orderService from "@/service/orderService";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class OrderUploadDeliverySlipNoDialog extends Vue {
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  disabledSaveButton = true;
  records: {
    deliveryId: string;
    deliverySlipNo: string;
    deliveryTraderNo: string;
    errorMsg: string;
  }[] = [];
  orderGuids: string[] = [];
  file = null;
  headers = [
    {
      text: "お届け先ID",
      value: "deliveryId",
      width: "20%",
    },
    {
      text: "配送伝票番号",
      value: "deliverySlipNo",
      width: "20%",
    },
    {
      text: "配送業者",
      value: "deliveryTraderNo",
      width: "20%",
    },
    {
      text: "エラー",
      value: "errorMsg",
    },
  ];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  get recordSize(): number {
    return this.records ? this.records.length : 0;
  }
  //---------------------------
  // methods
  //---------------------------
  /**
   * ダイアログを表示します.
   */
  public open(): void {
    this.orderGuids = [];
    this.records = [];
    this.file = null;
    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.orderGuids = [];
    this.records = [];
    this.file = null;
    this.isProgressing = false;
    this.isOpen = false;
  }

  async onCSVPicked(file: File): Promise<void> {
    this.isProgressing = true;
    if (file !== undefined && file !== null) {
      try {
        const csv = await this.readAsBinaryString(file);
        const buff = Buffer.from(csv, "binary");
        const utfCsv = iconv.decode(buff, "Shift_JIS");
        const csvData = this.parseCSV(utfCsv);

        let hasError = false;
        this.orderGuids = [];
        for (const aData of csvData) {
          if ( !aData[0] || !aData[1] || !aData[2]) {
            this.records.push({
              deliveryId: aData[0],
              deliverySlipNo: aData[1],
              deliveryTraderNo: aData[2],
              errorMsg: "CSVの形式が間違っています。",
            });
            hasError = true;
          } else {
            const orderGuid = await orderService.getOrderGuidByDeliveryId(aData[0]);
            if (!orderGuid){
              this.records.push({
                deliveryId: aData[0],
                deliverySlipNo: aData[1],
                deliveryTraderNo: aData[2],
                errorMsg: "お届け先ID（" + aData[0] + "）は存在しません。",
              });
              hasError = true;
            } else {
              if ( !this.orderGuids.includes(orderGuid) ){
                this.orderGuids.push(orderGuid);
              }
              this.records.push({
                deliveryId: aData[0],
                deliverySlipNo: aData[1],
                deliveryTraderNo: aData[2],
                errorMsg: "なし",
              });
            } 
          }
        }

        this.disabledSaveButton = hasError;

        this.isProgressing = false;
        console.log(this.orderGuids);
      } catch (e) {
        this.isProgressing = false;
        console.log(e);
      }
    } else {
      // ignore
      this.records = [];
      this.isProgressing = false;
    }
  }
  readAsBinaryString(file: File): Promise<string> {
    return new Promise<string>((resolve, reject): void => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        fileReader.result ? resolve(fileReader.result.toString()) : reject;
      };
      fileReader.onerror = reject;
      fileReader.readAsBinaryString(file);
    });
  }
  parseCSV(input: string): [] {
    const records = parse(input, {
      skip_empty_lines: true,
    });
    return records;
  }

  /**
   * 一括更新します.
   */
  saveItems(): void {
    this.isProgressing = true;
    if (this.records && this.records.length > 0) {
      //update
      orderService
        .updateDeliverySlipNos(this.records)
        .then(() => {
          orderService.tryTransitionToImportedDeliverySlip(this.orderGuids).then(() => {
            // 注文のステータスが「配送伝票番号取り込み済」となった可能性があるので
            this.notifyUpdateOrderSuccess();
          })
          this.isProgressing = false;
          this.notifySuccess();
        })
        .catch((err) => {
          console.error(err);
          this.notifyError("配送伝票番号を更新できませんでした.");
        });
    }
  }

  /**
   * 処理成功
   */
  @Emit("onSuccess")
  public notifySuccess(): boolean {
    return true;
  }
  /**
   * 注文の更新処理成功
   */
  @Emit("onUpdateOrderSuccess")
  public notifyUpdateOrderSuccess(): boolean {
    return true;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
