



























































































































































































import { Member, Coupon, DeliverdCoupon } from "@/graphql/client";
import { NotificationState } from "@/store/modules/notification";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import memberService from "@/service/memberService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/member/MixinMemberDeliveryCouponDialogValidator.vue";
import deliverdCouponService from "@/service/deliverdCouponService";
import couponService from "@/service/couponService";
import MemberDeliverdCouponDeleteDialog from "@/components/organisms/member/MemberDeliverdCouponDeleteDialog.vue";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
    MemberDeliverdCouponDeleteDialog,
  },
})
export default class MemberDeliveryCouponDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly itemForm!: HTMLFormElement;
  @Ref() readonly deleteDialog!: MemberDeliverdCouponDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  member: Member = memberService.defaultMember;
  item: DeliverdCoupon = deliverdCouponService.defaultDeliverdCoupon;
  coupons: Coupon[] = [];
  deliverdCoupons: DeliverdCoupon[] = [];
  expirationDayMenu = false;
  expirationDay = "";
  expirationTime = "";
  hours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];


  headers = [
    {
      text: "注文番号",
      value: "order",
      width: "120px",
    },
    {
      text: "配布日時",
      value: "createdAt",
      width: "120px",
      formatter: this.formatDateTime,
    },
    {
      text: "クーポン名称",
      value: "couponTitle",
    },
    {
      text: "額面",
      value: "couponAmount",
      width: "120px",
      formatter: this.formatPrice,
    },
    {
      text: "使用条件",
      value: "couponMinPriceToUse",
      width: "15%",
    },
    {
      text: "使用期限",
      value: "expirationDate",
      width: "120px",
      formatter: this.formatDateTime,
    },
    {
      text: "使用",
      value: "isUsed",
      width: "120px",
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 期限の入力値から日時を取得
   */
  get expirationDate(): string {
    const target = moment(this.expirationDay + " " + this.expirationTime);
    if (target.isValid()) {
      return target.toISOString();
    }
    return "";
  }
  //---------------------------
  // methods
  //---------------------------
  private async fetchData(member: Member): Promise<void> {
    this.member = memberService.defaultMember;
    if (member.guid) {
      const result = await memberService.getMember(member.guid);
      if (result) {
        this.member = result;


        //配布済みクーポン一覧
        const deliverdCoupons = await deliverdCouponService.allDeliverdCoupons(member.guid);
        if (deliverdCoupons) {
          this.deliverdCoupons = deliverdCoupons;
        }
      }
    }

    //フォームに表示するためにフォーマット変換
    this.expirationDay = "";
    this.expirationTime = "";

    //クーポン一覧
    const coupons = await couponService.allCoupons();
    if (coupons) {
      this.coupons = coupons;
    }
  }

  /**
   * ダイアログを表示します.
   */
  public async open(member: Member): Promise<void> {
    await this.fetchData(member);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }
  /**
   * クーポンを配布します。
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      this.item.expirationDate = this.expirationDate;
      this.item.member = this.member;
      //create
      deliverdCouponService
        .createDeliverdCoupon(this.item)
        .then((result) => {
          this.notifySuccess(result as DeliverdCoupon);
        })
        .catch((err) => {
          console.error(err);
          this.notifyError("クーポンの配布ができませんでした.");
        });
    }
  }

  /**
   * 配布済みクーポン削除ダイアログを表示します.
   */
  public async deleteDeliverdCoupon(deliverdCoupon: DeliverdCoupon): Promise<void> {
    //あらためて取得
    let delivCoupon = await deliverdCouponService.getDeliverdCoupon(deliverdCoupon.guid);
    if (!delivCoupon ) {
      alert('すでに削除されています。');
      await this.fetchData(this.member);
      return;
    } else if (delivCoupon.isUsed ) {
      alert('すでに使用済みのため、削除できません。');
      return;
    } else{
      //削除
      this.deleteDialog.open(delivCoupon);
    }
  }

  /**
   * 登録成功
   */
  @Emit("onSuccess")
  public notifySuccess(created: DeliverdCoupon): DeliverdCoupon {
    return created;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }


  /**
   * クーポン削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: DeliverdCoupon): void {
    //一覧からも削除
    const newDeliverdCoupons = this.deliverdCoupons.filter((item) => item.guid !== deleted.guid);
    this.deliverdCoupons = newDeliverdCoupons;

    this.deleteDialog.close();
    NotificationState.notifySuccess("クーポンを削除しました！");
  }
}
