
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, numeric } from "vuelidate/lib/validators";

@Component
export default class MixinMaxOrderAmountForDeferredPaymentEditorValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //NP後払い上限金額
      maxOrderAmountForDeferredPayment: { required, numeric },
      deferredPaymentCharge: { required, numeric },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * NP後払い上限金額入力エラーメッセージを返します.
   */
  get maxOrderAmountForDeferredPaymentErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.maxOrderAmountForDeferredPayment?.$dirty) return errors;
    !this.$v.item.maxOrderAmountForDeferredPayment?.required &&
      errors.push("NP後払い上限金額を入力してください.");
    !this.$v.item.maxOrderAmountForDeferredPayment?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }

  /**
   * NP後払い手数料入力エラーメッセージを返します.
   */
  get deferredPaymentChargeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.deferredPaymentCharge?.$dirty) return errors;
    !this.$v.item.deferredPaymentCharge?.required &&
      errors.push("NP後払い手数料を入力してください.");
    !this.$v.item.deferredPaymentCharge?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }
}
