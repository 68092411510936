





































































































































































































































import { Member } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import memberService from "@/service/memberService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import appService from "@/service/appService";
import MixinValidator from "@/components/mixins/member/MixinMemberEditDialogValidator.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class MemberEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly informationForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: Member = memberService.defaultMember;
  valid = false;
  prefectures = appService.prefectures;
  sexTypes = appService.sexTypes;
  birthDayMenu = false;
  birthDay = "";
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 誕生日の入力値から日時を取得
   */
  get birthDate(): string | null {
    console.log(this.birthDay);
    return this.birthDay ? moment(this.birthDay).utc(true).toISOString() : null;
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の登録日時を取得
   */
  get formattedCreatedAt(): string {
    return this.item.createdAt
      ? moment(this.item.createdAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の更新日時を取得
   */
  get formattedUpdatedAt(): string {
    return this.item.updatedAt
      ? moment(this.item.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  /**
   * メールマガジン購読中の場合は「購読中」、購読中でない場合は「未購読」という文字列を返します.
   */
  get formattedHasSubscriptionToMailzine(): string {
    return this.item.hasSubscriptionToMailzine
      ? "メールマガジン購読中"
      : "メールマガジン未購読";
  }
  /**
   * 表示中の場合は「表示中（ON）」、表示中でない場合は「表示中（OFF）」という文字列を返します.
   */
  get formattedIsAvailable(): string {
    return this.getIsAvailableLabel(
      this.item.isAvailable ? this.item.isAvailable : false
    );
  }
  //---------------------------
  // methods
  //---------------------------
  public async showMemberMypage(member: Member) {
    await memberService.showMypageAsMember(member);
  }

  async fetchData(item: Member): Promise<void> {
    this.item = memberService.defaultMember;
    if (item.guid) {
      const result = await memberService.getMember(item.guid);
      if (result) {
        this.item = result;
        //フォームに表示するためにフォーマット変換
        this.birthDay = this.item.birthday
          ? moment(this.item.birthday).format("YYYY-MM-DD")
          : "";
      }
    }
  }
  /**
   * ダイアログを表示します.
   */
  public async open(item: Member): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      this.item.birthday = this.birthDate;
      if (this.item.guid) {
        //update
        memberService
          .updateMember(this.item)
          .then((result) => {
            this.isProgressing = true;
            this.notifyUpdateSuccess(result as Member);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("顧客の更新ができませんでした.");
          });
      } else {
        //create
        memberService
          .createMember(this.item)
          .then((result) => {
            this.isProgressing = true;
            this.notifyCreateSuccess(result as Member);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("顧客の登録ができませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: Member): Member {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: Member): Member {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
