import { ShopSetting } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * ショップに関する機能を提供します.
 */
class ShopService {
  /**
   * 初期状態の Shop
   */
  public get defaultShop(): ShopSetting {
    return {
      guid: "",
      shopCode: "",
      shopName: "",
      contactAddress: "",
      contactEmailAddress: "",
      maxOrderAmountForDeferredPayment: 0,
      deferredPaymentCharge:0,
      privacyPolicy: "",
      specifiedCommercialTranscationAct: "",
      minOrderAmountForFreeShipping: 0,
      discountRateDeferementDays: 0,
      pointGrantRate: 0,
    } as ShopSetting;
  }

  /**
   * ショップ設定を1件取得して返します.
   * @returns ショップ設定
   */
  public async getShopSetting() {
    const response = await sdk.shopSetting();
    return response.shopSetting as ShopSetting;
  }

  /**
   * ショップ設定を追加します
   * @returns 
   */
  public async createShopSetting() {
    const setting = await this.getShopSetting();
    if (setting != null) return setting;
    try {
      const response = await sdk.createShopSetting({
        input: {
          shopCode: "",
          shopName: "",
        },
      });
      if (response?.createShopSetting) {
        return response.createShopSetting;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * ショップ設定（一部）を更新します.
   * @param input ショップ設定の更新情報
   * @returns 更新完了後のショップ設定
   */
  public async updateShopSetting(input: ShopSetting) {
    try {
      const response = await sdk.updateShopSetting({
        updateShopSettingInput: {
          guid: input.guid,
          // shopCode: input.shopCode,
          shopName: input.shopName,
          contactAddress: input.contactAddress,
          contactEmailAddress: input.contactEmailAddress,
          // maxOrderAmountForDeferredPayment:
          //   input.maxOrderAmountForDeferredPayment,
          privacyPolicy: input.privacyPolicy,
          specifiedCommercialTransactionAct:
            input.specifiedCommercialTransactionAct,
          // minOrderAmountForFreeShipping: input.minOrderAmountForFreeShipping,
          // discountRateDefermentDays: input.discountRateDefermentDays,
          // pointGainRate: input.pointGainRate,
        },
      });

      if (response?.updateShopSetting) {
        return response.updateShopSetting;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * ジャンプアップ割引有効日数を更新します.
   * @param input ショップ設定の更新情報(ジャンプアップ割引有効日数のみ参照)
   * @returns 更新完了後のショップ設定
   */
  public async updateDiscountRateDeferementDays(input: ShopSetting) {
    try {
      const response = await sdk.updateShopSetting({
        updateShopSettingInput: {
          guid: input.guid,
          discountRateDefermentDays: input.discountRateDefermentDays,
        },
      });

      if (response?.updateShopSetting) {
        return response.updateShopSetting;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * ポイント付与率を更新します.
   * @param input ショップ設定の更新情報(ポイント付与率のみ参照)
   * @returns 更新完了後のショップ設定
   */
  public async updatePointGrantRate(input: ShopSetting) {
    try {
      const response = await sdk.updateShopSetting({
        updateShopSettingInput: {
          guid: input.guid,
          pointGrantRate: input.pointGrantRate,
        },
      });

      if (response?.updateShopSetting) {
        return response.updateShopSetting;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 送料無料条件を更新します.
   * @param input ショップ設定の更新情報(送料無料条件のみ参照)
   * @returns 更新完了後のショップ設定
   */
  public async updateMinOrderAmountForFreeShipping(input: ShopSetting) {
    try {
      const response = await sdk.updateShopSetting({
        updateShopSettingInput: {
          guid: input.guid,
          minOrderAmountForFreeShipping: input.minOrderAmountForFreeShipping,
        },
      });

      if (response?.updateShopSetting) {
        return response.updateShopSetting;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * NP後払い上限金額を更新します.
   * @param input ショップ設定の更新情報(NP後払い上限金額のみ参照)
   * @returns 更新完了後のショップ設定
   */
  public async updateMaxOrderAmountForDeferredPayment(input: ShopSetting) {
    try {
      const response = await sdk.updateShopSetting({
        updateShopSettingInput: {
          guid: input.guid,
          maxOrderAmountForDeferredPayment:
            input.maxOrderAmountForDeferredPayment,
          deferredPaymentCharge:
            input.deferredPaymentCharge,
        },
      });

      if (response?.updateShopSetting) {
        return response.updateShopSetting;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ShopService();
