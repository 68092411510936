











































































































































































































































































































































































































































































































































































































































































































import {
  Item,
  Option,
  ItemCategory,
  ItemBrand,
  Catalog,
} from "@/graphql/client";
import { Purpose } from "@/graphql/client";
import { Component, Ref, Emit, Mixins, Watch } from "vue-property-decorator";
import itemService from "@/service/itemService";
import itemCategoryService from "@/service/itemCategoryService";
import purposeService from "@/service/purposeService";
import optionService from "@/service/optionService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import itemBrandService from "@/service/itemBrandService";
import catalogService from "@/service/catalogService";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinValidator from "@/components/mixins/item/MixinItemEditDialogValidator.vue";
import ImagePicker from "@/components/molecules/ImagePicker.vue";
import moment from "moment";
import typeService from "@/service/typeService";

@Component({
  components: {
    ProgressLinear,
    ImagePicker,
  },
})
export default class ItemEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly itemForm!: HTMLFormElement;
  @Ref() readonly imagePicker01!: ImagePicker;
  @Ref() readonly imagePicker02!: ImagePicker;
  @Ref() readonly imagePicker03!: ImagePicker;
  @Ref() readonly imagePicker04!: ImagePicker;
  @Ref() readonly imagePicker05!: ImagePicker;
  @Ref() readonly imagePicker06!: ImagePicker;
  @Ref() readonly imagePicker07!: ImagePicker;
  @Ref() readonly imagePicker08!: ImagePicker;
  
  //---------------------------
  // data
  //---------------------------
  isLoading = true;

  purposes = [] as Purpose[];
  options: Option[] = [];
  itemCategories: ItemCategory[] = [];
  itemBrands: ItemBrand[] = [];
  taxTypes = typeService.taxTypes;
  isOpen = false;
  isProgressing = false;
  item: Item = itemService.defaultItem;
  publicationDayMenu = false;
  publicationDay = "";
  publicationTime = "";
  publicationEndDayMenu = false;
  publicationEndDay = "";
  publicationEndTime = "";
  valid = false;
  hours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 公開開始日の入力値から日時を取得
   */
  get publicationDate(): string {
    return moment(this.publicationDay + " " + this.publicationTime).format();
  }
  /**
   * 公開開始日の入力値から日時を取得
   */
  get publicationEndDate(): string {
    return moment(
      this.publicationEndDay + " " + this.publicationEndTime
    ).format();
  }
  /**
   * 表示中の場合は「表示中（ON）」、表示中でない場合は「表示中（OFF）」という文字列を返します.
   */
  get formattedIsAvailable(): string {
    return this.getIsAvailableLabel(
      this.item.isAvailable ? this.item.isAvailable : false
    );
  }

  get formattedOnDiscount(): string {
    return this.item.onDiscount ? "割引対象" : "割引対象外";
  }

  /**
   * カタログ注文のみの場合は「カタログ注文のみ（ON）」、カタログ注文のみでない場合は「カタログ注文のみ（OFF）」という文字列を返します.
   */
  get formattedOnlyForCatalogOrder(): string {
    return this.item.onlyForCatalogOrder
      ? "カタログ注文のみ（ON）"
      : "カタログ注文のみ（OFF）";
  }
  /**
   * 自家用商品の場合は「自家用商品（ON）」、自家用商品でない場合は「自家用商品（OFF）」という文字列を返します.
   */
  get formattedForPrivateUse(): string {
    return this.item.forPrivateUse ? "自家用商品（ON）" : "自家用商品（OFF）";
  }
  /**
   * 産地直送品の場合は「産地直送品（ON）」、産地直送品でない場合は「産地直送品（OFF）」という文字列を返します.
   */
  get formattedSendDirectly(): string {
    return this.item.sendDirectly ? "産地直送品（ON）" : "産地直送品（OFF）";
  }
  /**
   * フレッシュ品の場合は「フレッシュ品（ON）」、フレッシュ品でない場合は「フレッシュ品（OFF）」という文字列を返します.
   */
  get formattedIsFlesh(): string {
    return this.item.isFresh ? "フレッシュ品（ON）" : "フレッシュ品（OFF）";
  }
  /**
   * 送料無料の場合は「送料無料（ON）」、送料無料でない場合は「送料無料（OFF）」という文字列を返します.
   */
  get formattedIsPostageFree(): string {
    return this.item.isPostageFree ? "送料無料（ON）" : "送料無料（OFF）";
  }
  /**
   * 店舗受取可の場合は「店舗受取可（ON）」、店舗受取可でない場合は「店舗受取可（OFF）」という文字列を返します.
   */
  get formattedCanPickupAtStore(): string {
    return this.item.canPickupAtStore
      ? "店舗受取可（ON）"
      : "店舗受取可（OFF）";
  }
  get formattedHasPersonalizedProperty(): string {
    return this.item.hasPersonalizedProperty
      ? "名入商品（ON）"
      : "名入商品（OFF）";
  }
  get formattedCannotBeAddedNoshi(): string {
    return this.item.cannotBeAddedNoshi
      ? "熨斗なし"
      : "熨斗あり";
  }
  get formattedCannotBeAddedMessageCard(): string {
    return this.item.cannotBeAddedMessageCard
      ? "メッセージカードなし"
      : "メッセージカードあり";
  }
  get formattedCannotBeAddedWarppingPaper(): string {
    return this.item.cannotBeAddedWarppingPaper
      ? "包装紙なし"
      : "包装紙あり";
  }
  //---------------------------
  // methods
  //---------------------------
  @Watch('publicationDay')
  public onChangePublicationDay() {
    this.setPublicationDate();
  }
  @Watch('publicationTime')
  public onChangePublicationTime() {
    this.setPublicationDate();
  }
  @Watch('publicationEndDay')
  public onChangePublicationEndDay() {
    this.setPublicationEndDate();
  }
  @Watch('publicationEndTime')
  public onChangePublicationEndTime() {
    this.setPublicationEndDate();
  }

  setPublicationDate() {
    this.item.publicationDate = moment(this.publicationDay + " " + this.publicationTime).format();  
  }

  setPublicationEndDate() {
    this.item.publicationEndDate = moment(this.publicationEndDay + " " + this.publicationEndTime).format();  
  }

  /**
   * カタログコード入力時の処理
   */
  onInputCatalogCode() {
    if (this.item.catalogCodes) {
      //重複は省く
      this.item.catalogCodes = Array.from(this.item.catalogCodes);
    }
  }


  private async fetchData(item: Item): Promise<void> {
    this.isLoading = true;
    this.item = itemService.defaultItem;
    
    //用途一覧取得
    this.purposes = await purposeService.allPurposes();
    //オプション一覧取得
    this.options = await optionService.allOptions();

    //商品カテゴリ取得
    this.itemCategories = await itemCategoryService.getToplevelCategories();

    //商品ブランド取得
    this.itemBrands = await itemBrandService.allItemBrands();

    //表示データ取得
    if (item.guid) {
      itemService.getItem(item.guid).then((item) => {
        if (item) {
          this.item = item;

          //フォームに表示するためにフォーマット変換
          this.publicationDay = moment(this.item.publicationDate).format(
            "YYYY-MM-DD"
          );
          this.publicationTime = moment(this.item.publicationDate).format(
            "HH:00"
          );
          this.publicationEndDay = moment(this.item.publicationEndDate).format(
            "YYYY-MM-DD"
          );
          this.publicationEndTime = moment(this.item.publicationEndDate).format(
            "HH:00"
          );
        }
      });
    } else {
      //フォームに表示するためにフォーマット変換
      this.publicationDay = moment(this.item.publicationDate).format(
            "YYYY-MM-DD"
      );
      this.publicationTime = moment(this.item.publicationDate).format(
        "HH:00"
      );
      this.publicationEndDay = moment(this.item.publicationEndDate).format(
        "YYYY-MM-DD"
      );
      this.publicationEndTime = moment(this.item.publicationEndDate).format(
        "HH:00"
      );
    }
    this.isLoading = false;
  }
  /**
   * ダイアログを表示します.
   */
  public open(item: Item): void {
    this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        itemService
          .updateItem(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyUpdateSuccess(result as Item);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("商品を更新できませんでした.");
          });
      } else {
        //create
        itemService
          .createItem(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyCreateSuccess(result as Item);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("商品を登録できませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: Item): Item {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: Item): Item {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
