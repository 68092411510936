























































































































































































import { Member, Order, OrderOption, OrderState } from "@/graphql/client";
import { Component, Ref, Emit, Vue, Mixins } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import typeService from "@/service/typeService";
import appService from "@/service/appService";
import orderService from "@/service/orderService";
import { required, helpers } from "vuelidate/lib/validators";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import DeliveryCards from "@/components/molecules/order/DeliveryCards.vue";
import OrderOptionCards from "@/components/molecules/order/OrderOptionCards.vue";
import MemberCard from "@/components/molecules/order/MemberCard.vue";
import SenderCard from "@/components/molecules/order/SenderCard.vue";
import PaymentCard from "@/components/molecules/order/PaymentCard.vue";
import OrderCommunicationMessageDialog from "@/components/organisms/order/OrderCommunicationMessageDialog.vue";
import OrderHandoverMessageDialog from "@/components/organisms/order/OrderHandoverMessageDialog.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import MixinOrderAction from "@/components/mixins/order/MixinOrderAction.vue";
import moment from "moment";
import memberService from "@/service/memberService";

import Notification from "@/components/molecules/Notification.vue";
import { NotificationState } from "@/store/modules/notification";

@Component({
  components: {
    Notification,
    ProgressLinear,
    DeliveryCards,
    OrderOptionCards,
    SenderCard,
    MemberCard,
    PaymentCard,
    OrderCommunicationMessageDialog,
    OrderHandoverMessageDialog,
  },
})
export default class OrderEditDialog extends Mixins(
  MixinFormatter,
  MixinOrderAction
) {
  @Ref() readonly communicationDialog!: OrderCommunicationMessageDialog;
  @Ref() readonly handoverDialog!: OrderHandoverMessageDialog;
  @Ref() readonly orderForm!: HTMLFormElement;
  @Validations()
  validations = {
    item: {
      //タイトル
      name: { required },
    },
    publicationDate: {
      validDate: (value: string, parentVm: Vue): boolean => {
        const targetDate = helpers.ref("publicationEndDate", this, parentVm);
        return moment(value).isBefore(moment(targetDate));
      },
    },
    publicationEndDate: {
      validDate: (value: string, parentVm: Vue): boolean => {
        const targetDate = helpers.ref("publicationDate", this, parentVm);
        return moment(value).isAfter(moment(targetDate));
      },
    },
  };
  //---------------------------
  // data
  //---------------------------
  isLoading = true;

  taxTypes = ["標準税率（10%）", "軽減税率（8%）"];
  isOpen = false;
  isProgressing = false;
  item: Order = orderService.defaultOrder;
  member: Member = memberService.defaultMember;
  commonOrderOptions: OrderOption[] = [];
  valid = false;
  prefectures = appService.prefectures;
  orderState = typeService.orderStatus;

  navDeliveries: any[] = [];

  navItemLineMap: {[key: string]: number} = {};
  navItemLineMapV1: {[key: string]: number} = {};
  //---------------------------
  // mounted
  //---------------------------
  get type(): string {
    return NotificationState.type;
  }
  get message(): string {
    return NotificationState.message;
  }
  get visibility(): boolean {
    return NotificationState.visibility;
  }
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  public orderActions(item: Order): {
    value: string;
    label: string;
    beforeOrderStatus: OrderState[];
  }[] {
    return orderService.getNextActions(item);
  }

  public unreadHandoverMessageCount(order: Order) {
    if(order.handoverMessages) {
      let filtered =order.handoverMessages.filter((item) => {
        return !item?.readDate;
      });
      return filtered.length;
    }
    return 0;
  }
  public hasUnreadHandoverMessage(order: Order) {
    return this.unreadHandoverMessageCount(order) > 0;
  }


  private async fetchData(item: Order): Promise<void> {
    this.isLoading = true;

    //表示データ取得
    this.item = orderService.defaultOrder;
    if (item.guid) {
      this.navItemLineMap = await orderService.getNavItemLineNumberMap(item.guid);
      //古いロジック用のMap
      this.navItemLineMapV1 = await orderService.getNavItemLineNumberMapV1(item.guid);
      const order = await orderService.getOrder(item.guid);
      if (order) {
        this.item = order;
        this.navDeliveries = await orderService.getNavDeliveries(order);
        if (order.member) {
          this.member = order.member;
        }
      }
    }
    this.isLoading = false;
  }
  /**
   * ダイアログを表示します.
   */
  public open(item: Order): void {
    this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  private save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        setTimeout(() => {
          this.notifyUpdateSuccess();
        }, 3000);
      } else {
        //ignore
      }
    }
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(): string {
    return "updated";
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(): string {
    return "error!";
  }

  /**
   * 会員になりかわって注文者のマイページを表示します.
   */
  public showMemberMypage(item: Order): void {
    if (item.member) {
      memberService.showMypageAsMember(item.member);
    }
  }

  /**
   * コミュニケーションメッセージダイアログを表示します.
   */
  public openCommunicationMessageDialog(item: Order): void {
    this.communicationDialog.open(item);
  }

  /**
   * 申し送りメッセージダイアログを表示します.
   */
  public openHandoverMessageDialog(item: Order): void {
    this.handoverDialog.open(item);
  }

  public async updateUnleadHandoverMessageCount(updated: Order): Promise<void> {
    this.item = updated;
  }

  /**
   * 注文ステータス変更ダイアログの更新成功時.
   */
  onStatusChangeSuccess(updated: Order): void {
    this.item = updated;
    this.statusChangeDialog.close();
    NotificationState.notifySuccess("注文ステータスを変更しました！");
  }

  /**
   * 注文ステータス変更NG
   */
  onStatusChangeError(message: string): void {
    this.statusChangeDialog.close();
    NotificationState.notifyError(message);
  }
}
