
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required,numeric } from "vuelidate/lib/validators";

@Component
export default class MixinPropertyEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //名称
      name: { required },
      //スラッグ名称
      slugName: { required },
      propertyType: { required },
      displayOrder: { required, numeric },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * 名称の入力エラーメッセージを返します.
   */
  get nameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.name?.$dirty) return errors;
    !this.$v.item.name?.required && errors.push("名称を入力してください.");
    return errors;
  }
  /**
   * スラッグ名称の入力エラーメッセージを返します.
   */
  get slugNameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.slugName?.$dirty) return errors;
    !this.$v.item.slugName?.required &&
      errors.push("スラッグ名称を入力してください.");
    // !this.$v.item.slugName?.alphaNum &&
    //   errors.push("スラッグ名称は半角英数字のみです.");
    return errors;
  }

  /**
   * 型の入力エラーメッセージを返します.
   */
  get propertyTypeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.propertyType?.$dirty) return errors;
    !this.$v.item.propertyType?.required &&
      errors.push("型を入力してください.");
    return errors;
  }

  /**
   * 表示順入力エラーメッセージを返します.
   */
  get displayOrderErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.displayOrder?.$dirty) return errors;
    !this.$v.item.displayOrder?.required &&
      errors.push("表示順を入力してください.");
    !this.$v.item.displayOrder?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }
}
