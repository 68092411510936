






















import Vue from "vue";

export default Vue.extend({
  data: () => ({
    page_groups: [
      {
        title: "お知らせ",
        items: [
          [
            "mdi-information-outline",
            "ショップのお知らせ",
            "/setting/information",
          ],
        ],
      },
      {
        title: "商品の設定",
        items: [
          ["mdi-gift-outline", "用途", "/setting/purpose"],
          ["mdi-file-tree-outline", "商品カテゴリ", "/setting/item_category"],
          [
            "mdi-bookmark-multiple-outline",
            "商品ブランド",
            "/setting/item_brand",
          ],
          // ["mdi-book-open-variant", "カタログマスタ", "/setting/catalog"],
        ],
      },
      {
        title: "オプションの設定",
        items: [
          ["mdi-order-bool-descending", "オプション", "/setting/option"],
          [
            "mdi-check-circle-outline",
            "オプションアイテム",
            "/setting/option_item",
          ],
          ["mdi-alpha-p-circle-outline", "プロパティ", "/setting/property"],
        ],
      },
      {
        title: "割引の設定",
        items: [
          [
            "mdi-calendar-clock-outline",
            "割引有効日数",
            "/setting/discount_rate_deferment_days",
          ],
          ["mdi-sale", "ジャンプアップ割引率", "/setting/discount_rate"],
        ],
      },
      {
        title: "ポイントの設定",
        items: [
          [
            "mdi-ticket-percent-outline",
            "ポイント付与率",
            "/setting/point_rate",
          ],
        ],
      },
      {
        title: "クーポンの設定",
        items: [["mdi-ticket-outline", "クーポン", "/setting/coupon"]],
      },
      {
        title: "送料の設定",
        items: [
          ["mdi-truck", "配送地域別送料", "/setting/domestic_postage_fee"],
        ],
      },
      {
        title: "後払いの設定",
        items: [
          [
            "mdi-arrow-collapse-up",
            "NP後払い金額",
            "/setting/deferred_payment",
          ],
        ],
      },
      {
        title: "その他の設定",
        items: [
          ["mdi-store", "ショップ基本情報", "/setting/shop_setting"],
          ["mdi-cash-multiple", "商品価格帯", "/setting/item_price_range"],
          ["mail_outline", "メールテンプレート", "/setting/email_template"],
        ],
      },
    ],
  }),
});
