import {
  Order,
  CommunicationMessage,
  HandoverMessage,
  OrderFilter,
  Sort,
  OrderOrderByInput,
  OrderState,
  Nav,
} from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";
import { dataURLToBlob } from "blob-util";
import typeService from "@/service/typeService";
import iconv from "iconv-lite";
import stringify from "csv-stringify";
import moment from "moment";
/**
 * 注文に関する機能を提供します.
 */
class OrderService {
  /**
   * 初期状態の Order
   */
  public get defaultOrder(): Order {
    return {
      guid: "",
      status: null,
      transactionDate: "",
      paymentMethodType: null,
      discountRate: 0,
      discountPrice: 0,
      couponDiscountPrice: 0,
      pointDiscountPrice: 0,
      memberId: "",
      senderName: "",
      senderNameKana: "",
      senderPostalCode: "",
      senderPrefecture: "",
      senderAddress1: "",
      senderAddress2: "",
      senderAddress3: "",
      senderPhone: "",
      orderNote: "",
      createdAt: "",
      updatedAt: "",
      member: undefined,
      deliveries: undefined,
      handoverMessages: undefined,
      communicationMessages: undefined,
    };
  }

  /**
   * 初期状態の CommunicationMessage
   */
  public get defaultCommunicationMessage(): CommunicationMessage {
    return {
      guid: "",
      order: undefined,
      senderName: "",
      subject: "",
      message: "",
      notifyDate: "",
      readDate: "",
      imageUrl01: "",
      imageUrl02: "",
      imageUrl03: "",
      imageUrl04: "",
      imageUrl05: "",
      createdAt: "",
      updatedAt: "",
    };
  }

  /**
   * 初期状態の HandoverMessage
   */
  public get defaultHandoverMessage(): HandoverMessage {
    return {
      guid: "",
      order: undefined,
      senderName: "",
      subject: "",
      message: "",
      readDate: "",
      createdAt: "",
      updatedAt: "",
    };
  }

  /**
   * 初期状態の Nav
   */
  public get defaultNav(): Nav {
    return {
      id: "",
      memberId: "",
      name: "",
      purposeNo: undefined,
      totalPriceWithTax: 0,
      postageFeeWithTax: 0,
      deliveryDate: "",
      transactionDate: "",
      np: undefined,
      site: undefined,
      deliveryDateSelect: undefined,
      senders: [],
      deliveries: []
    };
  }

  /**
   * すべての商品を取得して返します.
   * @returns すべての商品
   */
  public async allOrders() {
    return this.searchOrders();
  }

  /**
   * OrderOptionを検索します.
   * @param condition 検索条件
   * @returns 検索結果
   */
  public async searchOrders(
    filter: OrderFilter | undefined = undefined,
    skip: number | undefined = undefined,
    take: number | undefined = undefined
  ) {
    const orderBY: OrderOrderByInput = {
      transactionDate: Sort.Desc,
    };
    const response = await sdk.orders({
      ordersFilter: filter,
      ordersSkip: skip,
      ordersTake: take,
      ordersOrderBy: orderBY,
    });
    return response.orders as Order[];
  }
  public async countOrders(
    filter: OrderFilter | undefined = undefined,
  ) {
    const response = await sdk.countOrders({
      filter: filter,
    });
    return response.countOrders as number;
  }


  /**
   * 指定したguidに一致する Nav を取得します.
   * @param guid GUID
   * @returns Nav
   */
   public async getNavs(guid: string) {
    const response = await sdk.navs({
      guid: guid,
    });
    return response.navs as Nav[];
  }

  /**
   * ある注文の、送り状ごとの商品行Noのマップを取得して返します.
   * @param guid 
   */
  public async getNavItemLineNumberMap(guid: string) {
    //Navにあてこむためのマップを生成
    const itemLineNoMap: {[key: string]: number} = {};
    const navs = await this.getNavs(guid);
    navs.some(aNav => {
      if (aNav.deliveries) {
        aNav.deliveries.forEach(delivery => {
          if (delivery?.items) {
            delivery.items.forEach(item => {
              if (item && item.includedGuids) {
                item.includedGuids.forEach(deliveryDetailGuid => {
                  if(deliveryDetailGuid) {
                    itemLineNoMap[deliveryDetailGuid] = item.deliveryItemRow ? item.deliveryItemRow : 0;
                  }
                });
              }
            });
          }
        });
      }
    });
    return itemLineNoMap;
  }

  /**
   * ある注文の、送り状ごとの商品行Noのマップを取得して返します（以前のimporterに対応したロジックです）
   * @param guid 
   */
   public async getNavItemLineNumberMapV1(guid: string) {
    //Navにあてこむためのマップを生成
    const itemLineNoMap: {[key: string]: number} = {};
    const navs = await this.getNavs(guid);
    navs.some(aNav => {
      if (aNav.deliveries) {
        aNav.deliveries.forEach(delivery => {
          if (delivery?.items) {
            delivery.items.forEach(item => {
              if (item?.webCode && delivery.includedDeliveryIds) {
                delivery.includedDeliveryIds.forEach(deliveryId => {
                  itemLineNoMap[deliveryId + '_' + item.webCode] = item.deliveryItemRow ? item.deliveryItemRow : 0;
                });
              }
            });
          }
        });
      }
    });
    return itemLineNoMap;
  }

  public async getNavDeliveries(order: Order) {
    const navDeliveries: any[] = [];
    if ( order.guid && order.deliveries ){
      const deliveries = order.deliveries?.filter(delivery => {
        return delivery ? delivery.disabled == false : false;
      });
  
      const navs = await this.getNavs(order.guid);
        // お届けを並び替え
        navs.forEach(nav => {
          if (nav.deliveries){
            nav.deliveries.forEach(delivery => {
              const includedDeliveries: any[] = [];
              if (delivery && delivery.includedDeliveryIds){
                delivery.includedDeliveryIds.forEach(deliveryId =>{
                    const includedDelivery = deliveries.filter(d => d && d.id === deliveryId);
                    if (includedDelivery.length > 0) {
                        includedDeliveries.push(includedDelivery[0]);
                    }
                });
              }
              includedDeliveries.sort((a,b) => a.displayOrder - b.displayOrder);
              Object.defineProperty(delivery, 'includedDeliveries', {
                value: includedDeliveries,
                writable: true, 
                enumerable: true,
                configurable: false
              });
              // delivery.includedDeliveries = includedDeliveries;
              const min = Math.min(...includedDeliveries.map((p)=>p.displayOrder))
              Object.defineProperty(delivery, 'displayOrder', {
                value: min,
                writable: true, 
                enumerable: true,
                configurable: false
              });
              // delivery.displayOrder = min;
              navDeliveries.push(delivery);
            });
          }
            
        });
        navDeliveries.sort((a, b) => a.displayOrder - b.displayOrder);
    }
    return navDeliveries;
  }


  /**
   * 指定したguidに一致する Order を取得します.
   * @param guid GUID
   * @returns Order
   */
  public async getOrder(guid: string) {
    const response = await sdk.order({
      orderGuid: guid,
    });
    return response.order as Order;
  }

  /**
   * 指定した Order のNAVファイルをダウンロードします
   * @param guid
   */
  public async downloadNavFile(input: Order) {
    if (!input.guid) return;

    //改めて取得
    const order = await this.getOrder(input.guid);
    if (!order.guid) return;

    const response = await sdk.base64NavFile({
      guid: order.guid,
    });
    if (response.base64NavFile) {
      const blob = dataURLToBlob(response.base64NavFile);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "NAV_" + order.id + ".zip";
      link.click();
      link.remove();
    }
  }
  /**
   * 指定した Order のNP後払い請求CSVをダウンロードします
   * @param guid
   */
  public async downloadNPCsvFile(input: Order) {
    if (!input.guid) return;

    //改めて取得
    const order = await this.getOrder(input.guid);
    if (!order.guid) return;

    const csvData: any[] = [];

    //無効な明細は除外
    order.deliveries = order.deliveries?.filter(delivery => {
      return delivery?.disabled == false;
    });
    const deliveries = order.deliveries;
    
    moment.locale('ja');

    //配送予定日が最短の送状を取得
    let targetDelivery: any = undefined;
    let minDelivery: any = undefined;
    let num = 1;
    deliveries?.some((delivery, index) => {
      if (index==0) {
        targetDelivery = delivery;
      }
      if (delivery?.deliveryDate == null ) {
        //ginore
      } else {
        if ( minDelivery == undefined ) {
          minDelivery = delivery;
          num = index + 1;
        } else if (minDelivery.deliveryDate > delivery?.deliveryDate ) {
          minDelivery = delivery;
          num = index + 1;
        }
      }
    });
    if (minDelivery) {
      targetDelivery = minDelivery;
    }

    csvData.push({
      "運送会社名": "16",
      "配送伝票番号" : minDelivery?.deliverySlipNo,
      "加盟店注文日" : moment(order.transactionDate).format('L'),
      "NP取引ID" : "",
      "加盟店取引ID" : order.id,
      "購入者名" : "",
      "配達先番号" : num,
      "配達先氏名" : "",
      "配達先住所" : "",
      "顧客請求金額" : "",
      "決済手段" : "",
      "審査結果" : "",
    });
    stringify(
      csvData,
      {
        columns: [
          "運送会社名",
          "配送伝票番号",
          "加盟店注文日",
          "NP取引ID",
          "加盟店取引ID",
          "購入者名",
          "配達先番号",
          "配達先氏名",
          "配達先住所",
          "顧客請求金額",
          "決済手段",
          "審査結果",
        ],
        header: true,
      },
      function (err: Error | undefined, csv: string): void {
        const sjisCsv = iconv.encode(csv, "Shift_JIS");
        const blob = new Blob([sjisCsv], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "NP後払い請求CSV_" + order.id + ".csv";
        link.click();
        link.remove();
      }
    );
  }

  /**
   * 指定した Order の売上伝票をダウンロードします
   * @param guid
   */
  public async downloadSalesSlipExcel(order: Order) {
    if (!order.guid) return;

    const response = await sdk.base64SalesSlipExcel({
      guid: order.guid,
    });
    if (response.base64SalesSlipExcel) {
      const blob = dataURLToBlob(response.base64SalesSlipExcel);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "受注売上伝票_" + order.id + ".zip";
      link.click();
      link.remove();
    }
  }

  /**
   * 注文ステータスに応じたアクションの一覧を返します
   * @param order 現在のステータス
   */
  public getNextActions(order: Order) {
    if (!order || !order.status || order.status == undefined) {
      return [];
    }
    const currentState = order.status;

    const actions: {
      value: string;
      label: string;
      beforeOrderStatus: OrderState[];
    }[] = [];

    typeService.orderActions.forEach((action) => {
      if (action.beforeOrderStatus.includes(currentState)) {
        actions.push(action);
      }
    });
    return actions;
  }

  /**
   * 注文ステータスを「決済済」にします
   * @param order 注文
   */
  public async transitionToPaid(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid ) return false; //むり

    try {
      const response = await sdk.transitionToPaid({
        guid: order.guid,
      });

      if (response?.transitionToPaid) {
        return response.transitionToPaid;
      }
    } catch (err) {
      console.log(err);
    }
  }
  /**
   * 注文ステータスを「準備中」にします
   * @param order 注文
   */
  public async transitionToGettingReady(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid ) return false; //むり

    try {
      const response = await sdk.transitionToGettingReady({
        guid: order.guid,
      });

      if (response?.transitionToGettingReady) {
        return response.transitionToGettingReady;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 注文ステータスを「配送伝票取り込み中」にします
   * @param order 注文
   */
   public async transitionToImportingDeliverySlip(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToImportingDeliverySlip({
        guid: order.guid,
      });

      if (response?.transitionToImportingDeliverySlip) {
        return response.transitionToImportingDeliverySlip;
      }
    } catch (err) {
      console.log(err);
    }
  }
  /**
   * 注文ステータスを「配送伝票取り込み済」にします
   * @param order 注文
   */
   public async transitionToImportedDeliverySlip(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToImportedDeliverySlip({
        guid: order.guid,
      });

      if (response?.transitionToImportedDeliverySlip) {
        return response.transitionToImportedDeliverySlip;
      }
    } catch (err) {
      console.log(err);
    }
  }
  /**
   * 注文ステータスを「配送完了」にします
   * @param order 注文
   */
   public async transitionToDeliveryCompleted(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToDeliveryCompleted({
        guid: order.guid,
      });

      if (response?.transitionToDeliveryCompleted) {
        return response.transitionToDeliveryCompleted;
      }
    } catch (err) {
      console.log(err);
    }
  }
  /**
   * 注文ステータスを「返金処理中」にします
   * @param order 注文
   */
   public async transitionToRefunding(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToRefunding({
        guid: order.guid,
      });

      if (response?.transitionToRefunding) {
        return response.transitionToRefunding;
      }
    } catch (err) {
      console.log(err);
    }
  }
  /**
   * 注文ステータスを「NGのため保留」にします
   * @param order 注文
   */
   public async transitionToPendingForRejection(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToPendingForRejection({
        guid: order.guid,
      });

      if (response?.transitionToPendingForRejection) {
        return response.transitionToPendingForRejection;
      }
    } catch (err) {
      console.log(err);
    }
  }
  /**
   * 注文ステータスを「キャンセル済」にします
   * @param order 注文
   */
   public async transitionToCanceled(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToCanceled({
        guid: order.guid,
      });

      if (response?.transitionToCanceled) {
        return response.transitionToCanceled;
      }
    } catch (err) {
      console.log(err);
    }
  }
  /**
   * 注文ステータスを「削除済」にします
   * @param order 注文
   */
   public async transitionToDeleted(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToDeleted({
        guid: order.guid,
      });

      if (response?.transitionToDeleted) {
        return response.transitionToDeleted;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 注文ステータスを「NP後払い審査待ち」にします
   * @param order 注文
   */
   public async transitionToWaitingForAuthDeferredPayment(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToWaitingForAuthDeferredPayment({
        guid: order.guid,
      });

      if (response?.transitionToWaitingForAuthDeferredPayment) {
        return response.transitionToWaitingForAuthDeferredPayment;
      }
    } catch (err) {
      console.log(err);
    }
  }
  

  /**
   * 注文ステータスを「銀行振込待ち」にします
   * @param order 注文
   */
   public async transitionToWaitingForBankTransfer(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToWaitingForBankTransfer({
        guid: order.guid,
      });

      if (response?.transitionToWaitingForBankTransfer) {
        return response.transitionToWaitingForBankTransfer;
      }
    } catch (err) {
      console.log(err);
    }
  }
  

  /**
   * 注文ステータスを「クレジットカード決済待ち」にします
   * @param order 注文
   */
   public async transitionToWaitingForCreditCardPayment(order: Order) {
    //チェック
    const actions = this.getNextActions(order);
    const canAction = actions.find((action) => {
      if (!order || !order.status) return false;
      return action.beforeOrderStatus.includes(order.status);
    });

    if (!canAction || !order.guid) return false; //むり

    try {
      const response = await sdk.transitionToWaitingForCreditCardPayment({
        guid: order.guid,
      });

      if (response?.transitionToWaitingForCreditCardPayment) {
        return response.transitionToWaitingForCreditCardPayment;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 送り状を「配送完了」の状態にします
   */
  public async completeDeliveriesByIds(deliveryIds: string[]) {
    try {
      const response = await sdk.completeDeliveries({
        input: {
          deliveryIds,
        }
      });
      return response.completeDeliveries;
    } catch (err) {
      console.log(err);
    }
  }
  
  /**
   * 注文ステータスを「削除済」にします
   * @param order 注文
   */
  public async updateDeliverySlipNos(records: {
    deliveryId: string;
    deliverySlipNo: string;
    deliveryTraderNo: string;
   }[]) {
    try {
      for ( const record of records ) {

        const response = await sdk.updateDeliverySlipNo({
          input: {
            id: record.deliveryId,
            deliverySlipNo: record.deliverySlipNo,
            deliveryTraderNo: record.deliveryTraderNo,
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 注文ステータスを「削除済」にします
   * @param order 注文
   */
  public async tryTransitionToImportedDeliverySlip(orderGuids: string[]) {
    try {
      for ( const record of orderGuids ) {

        const response = await sdk.tryTransitionToImportedDeliverySlip({
          guid: record,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 指定したお届け先IDがシステムに存在する場合は true を返します
   * @param deliveryId 
   * @returns 
   */
  public async isExistDeliveryId(deliveryId: string) {
    try {
      const response = await sdk.delivery({
        key: {
          id: deliveryId,
        }
      });

      if (response?.delivery) {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  }

  /**
   * 指定したお届け先IDがシステムに存在する場合は true を返します
   * @param deliveryId 
   * @returns 
   */
  public async getOrderGuidByDeliveryId(deliveryId: string) {
    try {
      const response = await sdk.delivery({
        key: {
          id: deliveryId,
        }
      });

      if (response?.delivery) {
        return response?.delivery.order?.guid;
      }
    } catch (err) {
      console.log(err);
    }
    return null;
  }

}

export default new OrderService();
