








































































































































































import { Delivery, DeliveryDetail, Item, Order, OrderOptionPropertyValue, PropertyType } from "@/graphql/client";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import appService from "@/service/appService";
import { Component, ModelSync, Mixins, Prop, Watch } from "vue-property-decorator";
import OrderOptionCards from "@/components/molecules/order/OrderOptionCards.vue";
import orderService from "@/service/orderService";


@Component({
  components: {
    OrderOptionCards,
  },
})
export default class DeliveryCards extends Mixins(MixinFormatter) {
  @ModelSync("order", "updateOrder", { type: Object, required: true })
  readonly orderValue!: Order;

  @Prop({ type: Object, required: true })
  navItemLineNumberMap!: {[key: string]: number};

  @Prop({ type: Object, required: true })
  navItemLineNumberMapV1!: {[key: string]: number};

  @Prop({ type: Object, required: true })
  navDeliveries!: any[];
  //---------------------------
  // data
  //---------------------------
  prefectures = appService.prefectures;

  deliveries: Delivery[] = [];
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // watch
  //---------------------------
  @Watch('orderValue')
  updateOrder() {
    let self: any;
    self = this;

    if(self.orderValue && self.orderValue.deliveries != null ||  self.orderValue.deliveries != undefined) {
      const orderDeliveries = self.orderValue.deliveries;
      const length = orderDeliveries.length;
      
      self.deliveries = [];
      

      const ite = function* () {
        for (let i = 0; i < length; i++) {
          yield setTimeout(() => {
            const deliv: Delivery | null | undefined = orderDeliveries[i];
            if(deliv) {
              self.deliveries.push(deliv);
              ite.next();
            }
          });
        }
      }();
      ite.next();
    }
  }
  //---------------------------
  // methods
  //---------------------------
  public getNavItemLineNumber(delivery: Delivery | null, detail: DeliveryDetail | null) {
    if (delivery == null || detail == null) return 0;

    if (this.navItemLineNumberMap && this.navItemLineNumberMapV1) {
      const navItemLineMap = this.navItemLineNumberMap;
      if (detail?.guid) {
        const key = detail.guid;
        if ( navItemLineMap && navItemLineMap[key] ) {
          return '#' + navItemLineMap[key];
        } else {
          //対応する商品番号が取得できない場合は、古いロジックで取得
          const navItemLineMapV1 = this.navItemLineNumberMapV1;
          const keyV1 = delivery.id + '_' + detail?.webCode;
          if ( navItemLineMapV1 && navItemLineMapV1[keyV1] ) {
            return '#' + navItemLineMapV1[keyV1];
          }
        }
      }
    }
    return '';
  }
  public showOptionImage(imageName: string, imageData:string) {
    //画像を別ウィンドウで表示
    let win = window.open('about:blank', '_blank');
    if (win) {
      win.document.title = imageName;
      win.document.write("<h1>" + imageName + "</h1><img src='" + imageData + "' title=" + imageName + " />");
    }
  }

  isImageProperty(prop: OrderOptionPropertyValue) {
    if (prop.propertyType == PropertyType.OriginalMessageCard) return true;
    if (prop.propertyType == PropertyType.MaterialImage) return true;
    return false;
  }
  public calcDeliveryTotalPrice(delivery: Delivery): number {
    let totalPriceWithTax = 0;
    if (delivery.deliveryDetails && delivery.deliveryDetails.length > 0) {
      delivery.deliveryDetails.forEach((detail) => {
        if (detail?.priceWithTax) {
          totalPriceWithTax = totalPriceWithTax + detail.priceWithTax;
        }
      });
    }
    if (delivery.postageFeeWithTax) {
      totalPriceWithTax = totalPriceWithTax + delivery.postageFeeWithTax;
    }
    return totalPriceWithTax;
  }
}
