import {
  DeliveryCalendarDayType,
  OptionType,
  Order,
  OrderState,
  PaymentMethodType,
  PropertyType,
  TaxType,
} from "@/graphql/client";

/**
 * 各種種別に関する機能を提供します.
 */
class TypeService {
  //税種別 ---------------------------------------------------
  private readonly TAX_TYPES = [
    {
      value: TaxType.Normal,
      label: "標準税率（10%）",
    },
    {
      value: TaxType.Reduced,
      label: "軽減税率（8%）",
    },
  ];

  /**
   * 税種別をすべて返します.
   */
  public get taxTypes() {
    return this.TAX_TYPES;
  }

  /**
   * 指定した税種別に対応するラベル（日本語名称）を返します.
   * @param type 税種別
   * @returns 税種別に対応するラベル（日本語名称）
   */
  public getTaxTypeLabel(type: TaxType): string {
    switch (type) {
      case TaxType.Normal:
        return "標準税率（10%）";
      case TaxType.Reduced:
        return "軽減税率（8%）";
      default:
        return type as string;
    }
  }

  //日付種別 ---------------------------------------------------
  private readonly DELIVERY_CALENDAR_DAY_TYPES = [
    DeliveryCalendarDayType.NonFlesh,
    DeliveryCalendarDayType.NonBusiness,
  ];

  /**
   * 日付種別をすべて返します.
   */
  public get deliveryCalendarDayTypes() {
    return this.DELIVERY_CALENDAR_DAY_TYPES;
  }

  /**
   * 指定した日付種別に対応するラベル（日本語名称）を返します.
   * @param type 日付種別
   * @returns 日付種別に対応するラベル（日本語名称）
   */
  public getDeliveryCalendarDayTypeLabel(
    type: DeliveryCalendarDayType
  ): string {
    switch (type) {
      case DeliveryCalendarDayType.NonFlesh:
        return "フレッシュお届け不可日";
      case DeliveryCalendarDayType.NonBusiness:
        return "非営業日";
      default:
        return type as string;
    }
  }

  //プロパティ種別 ---------------------------------------------------
  private readonly PROPERTY_TYPES = [
    PropertyType.SinglelineText,
    PropertyType.MultilineText,
    PropertyType.Date,
    PropertyType.Numeric,
    PropertyType.MaterialImage,
    PropertyType.OriginalMessageCard,
    PropertyType.Delivery,
    PropertyType.PreviewNoshi,
    PropertyType.Gender,
  ];

  /**
   * プロパティ種別をすべて返します.
   */
  public get propertyTypes() {
    return this.PROPERTY_TYPES;
  }

  /**
   * 指定したプロパティ種別に対応するラベル（日本語名称）を返します.
   * @param type プロパティ種別
   * @returns プロパティ種別に対応するラベル（日本語名称）
   */
  public getPropertyTypeLabel(type: PropertyType): string {
    switch (type) {
      case PropertyType.SinglelineText:
        return "1行テキスト";
      case PropertyType.MultilineText:
        return "複数行テキスト";
      case PropertyType.Date:
        return "日付";
      case PropertyType.Numeric:
        return "数値";
      case PropertyType.Gender:
        return "性別";
      case PropertyType.MaterialImage:
        return "画像素材";
      case PropertyType.OriginalMessageCard:
        return "自作メッセージカード";
      case PropertyType.Delivery:
        return "送り状";
      case PropertyType.PreviewNoshi:
        return "熨斗のプレビュー";
      default:
        return type as string;
    }
  }

  //オプション種別 ---------------------------------------------------
  private readonly OPTION_TYPES = [
    {
      value: OptionType.WrappingPaper,
      label: "包装紙",
    },
    {
      value: OptionType.GiftBox,
      label: "ギフトBOX",
    },
    {
      value: OptionType.ThanksGift,
      label: "無料プレゼント",
    },
    {
      value: OptionType.MessageCard,
      label: "メッセージカード",
    },
    {
      value: OptionType.Noshi,
      label: "熨斗",
    },
    {
      value: OptionType.NameEngraving,
      label: "名入れ",
    },
    {
      value: OptionType.Other,
      label: "その他",
    },
  ];

  /**
   * オプション種別をすべて返します.
   */
  public get optionTypes() {
    return this.OPTION_TYPES;
  }

  /**
   * 指定したオプション種別に対応するラベル（日本語名称）を返します.
   * @param type オプション種別
   * @returns オプション種別に対応するラベル（日本語名称）
   */
  public getOptionTypeLabel(type: OptionType): string {
    switch (type) {
      case OptionType.WrappingPaper:
        return "包装紙";
      case OptionType.GiftBox:
        return "ギフトBOX";
      case OptionType.ThanksGift:
        return "無料プレゼント";
      case OptionType.MessageCard:
        return "メッセージカード";
      case OptionType.Noshi:
        return "熨斗";
      case OptionType.NameEngraving:
        return "名入れ";
      case OptionType.Other:
        return "その他";
      default:
        return type as string;
    }
  }

  //注文アクション ---------------------------------------------------

  private readonly ORDER_ACTIONS = [
    {
      value: "transitionToPaid",
      label: "決済済にする",
      beforeOrderStatus: [
        OrderState.WaitingForAuthDeferredPayment,
        OrderState.WaitingForBankTransfer,
        OrderState.WaitingForCreditCardPayment,
      ],
    },
    {
      value: "transitionToGettingReady",
      label: "配送準備を開始する",
      beforeOrderStatus: [OrderState.Paid],
    },
    {
      value: "transitionToImportingDeliverySlip",
      label: "配送伝票取り込み中にする",
      beforeOrderStatus: [OrderState.GettingReady],
    },
    {
      value: "transitionToImportedDeliverySlip",
      label: "配送伝票取り込み済にする",
      beforeOrderStatus: [
        OrderState.GettingReady,
        OrderState.ImportingDeliverySlip,
      ],
    },
    {
      value: "transitionToDeliveryCompleted",
      label: "発送完了にする",
      beforeOrderStatus: [
        OrderState.GettingReady,
        OrderState.ImportingDeliverySlip,
        OrderState.ImportedDeliverySlip,
        OrderState.PerticalDeliveryCompleted,
      ],
    },
    {
      value: "transitionToRefunding",
      label: "返金処理を開始する",
      beforeOrderStatus: [OrderState.Paid, OrderState.GettingReady],
    },
    {
      value: "transitionToPendingForRejection",
      label: "注文を保留する",
      beforeOrderStatus: [
        OrderState.WaitingForAuthDeferredPayment,
        OrderState.WaitingForBankTransfer,
      ],
    },
    {
      value: "transitionToCanceled",
      label: "キャンセルする",
      beforeOrderStatus: [OrderState.PendingForRejection, OrderState.Refunding],
    },
    {
      value: "transitionToDeleted",
      label: "削除する",
      beforeOrderStatus: [OrderState.Canceled],
    },
    {
      value: "transitionToWaitingForAuthDeferredPayment",
      label: "後払審査待ちにする",
      beforeOrderStatus: [OrderState.PendingForRejection],
    },
    {
      value: "transitionToWaitingForBankTransfer",
      label: "銀行振込待ちにする",
      beforeOrderStatus: [OrderState.PendingForRejection],
    },
    {
      value: "transitionToWaitingForCreditCardPayment",
      label: "クレジットカード決済待ちにする",
      beforeOrderStatus: [OrderState.PendingForRejection],
    },
  ];

  /**
   * 注文アクションをすべて返します.
   */
  public get orderActions() {
    return this.ORDER_ACTIONS;
  }

  //注文ステータス ---------------------------------------------------
  private readonly ORDER_STATUS = [
    {
      value: OrderState.Canceled,
      label: "キャンセル済",
    },
    {
      value: OrderState.Refunding,
      label: "返金処理中",
    },
    {
      value: OrderState.Deleted,
      label: "削除済",
    },
    {
      value: OrderState.GettingReady,
      label: "準備中",
    },
    {
      value: OrderState.ImportingDeliverySlip,
      label: "配送伝票取り込み中",
    },
    {
      value: OrderState.ImportedDeliverySlip,
      label: "配送伝票取り込み済",
    },
    {
      value: OrderState.Paid,
      label: "決済済",
    },
    {
      value: OrderState.PendingForRejection,
      label: "NGのため保留",
    },
    {
      value: OrderState.PerticalDeliveryCompleted,
      label: "一部発送完了",
    },
    {
      value: OrderState.DeliveryCompleted,
      label: "発送完了",
    },
    {
      value: OrderState.WaitingForAuthDeferredPayment,
      label: "後払審査待ち",
    },
    {
      value: OrderState.WaitingForBankTransfer,
      label: "銀行振込待ち",
    },
    {
      value: OrderState.WaitingForCreditCardPayment,
      label: "クレジットカード決済待ち",
    },
  ];

  /**
   * 注文ステータスをすべて返します.
   */
  public get orderStatus() {
    return this.ORDER_STATUS;
  }

  /**
   * 指定したオプション種別に対応するラベル（日本語名称）を返します.
   * @param type オプション種別
   * @returns オプション種別に対応するラベル（日本語名称）
   */
  public getOrderStateLabel(type: OrderState | null = null): string {
    if (!type) return "";
    switch (type) {
      case OrderState.Canceled:
        return "キャンセル済";
      case OrderState.Refunding:
        return "返金処理中";
      case OrderState.Deleted:
        return "削除済";
      case OrderState.GettingReady:
        return "準備中";
      case OrderState.ImportingDeliverySlip:
        return "配送伝票取り込み中";
      case OrderState.ImportedDeliverySlip:
        return "配送伝票取り込み済";
      case OrderState.Paid:
        return "決済済";
      case OrderState.PendingForRejection:
        return "NGのため保留";
      case OrderState.DeliveryCompleted:
        return "発送完了";
      case OrderState.PerticalDeliveryCompleted:
        return "一部発送完了";
      case OrderState.WaitingForAuthDeferredPayment:
        return "後払審査待ち";
      case OrderState.WaitingForBankTransfer:
        return "銀行振込待ち";
      case OrderState.WaitingForCreditCardPayment:
        return "クレジットカード決済待ち";
      default:
        return type as string;
    }
  }

  //支払い方法種別 ---------------------------------------------------
  private readonly PAYMENT_METHOD_TYPES = [
    {
      value: PaymentMethodType.BankTransferPayment,
      label: "銀行振込",
    },
    {
      value: PaymentMethodType.CreditCardPayment,
      label: "クレジットカード決済",
    },
    {
      value: PaymentMethodType.NpDeferredPayment,
      label: "NP後払い",
    },
  ];
  /**
   * オプション種別をすべて返します.
   */
  public get paymentMethodTypes() {
    return this.PAYMENT_METHOD_TYPES;
  }

  /**
   * 指定したオプション種別に対応するラベル（日本語名称）を返します.
   * @param type オプション種別
   * @returns オプション種別に対応するラベル（日本語名称）
   */
  public getPaymentMethodTypeLabel(
    type: PaymentMethodType | null = null
  ): string {
    if (!type) return "";
    switch (type) {
      case PaymentMethodType.BankTransferPayment:
        return "銀行振込";
      case PaymentMethodType.CreditCardPayment:
        return "クレジットカード決済";
      case PaymentMethodType.NpDeferredPayment:
        return "NP後払い";
      default:
        return type as string;
    }
  }
}

export default new TypeService();
