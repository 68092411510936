import { Property } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * プロパティに関する機能を提供します.
 */
class PropertyService {
  /**
   * 初期状態の Property
   */
  public get defaultProperty(): Property {
    return {
      guid: "",
      slugName: "",
      name: "",
      propertyType: null,
      updatedAt: "",
      createdAt: "",
      cautionText: "",
      placeholderText: "",
      displayOrder: 0,
      optionItems: [],
    } as Property;
  }

  /**
   * すべてのプロパティを取得して返します.
   * @returns すべてのプロパティ
   */
  public async allProperties() {
    const response = await sdk.properties();
    if (response?.properties) {
      return response.properties as Property[];
    }
    return [] as Property[];
  }

  /**
   * 指定したGUIDに一致するプロパティを取得して返します.
   * @param guid プロパティのGUID
   * @returns プロパティ
   */
  public async getProperty(guid: string) {
    const response = await sdk.property({ propertyGuid: guid });
    return response.property as Property;
  }

  /**
   * プロパティを登録します.
   * @param input プロパティの登録情報
   * @returns 登録完了後のプロパティ
   */
  public async createProperty(input: Property) {
    try {
      const response = await sdk.createProperty({
        createPropertyInput: {
          slugName: input.slugName,
          name: input.name,
          propertyType: input.propertyType,
          cautionText: input.cautionText,
          displayOrder: input.displayOrder,
          placeholderText: input.placeholderText,
        },
      });

      if (response?.createProperty) {
        return response.createProperty;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * プロパティを更新します.
   * @param input プロパティの更新情報
   * @returns 更新完了後のプロパティ
   */
  public async updateProperty(input: Property) {
    try {
      const response = await sdk.updateProperty({
        updatePropertyInput: {
          guid: input.guid,
          slugName: input.slugName,
          name: input.name,
          propertyType: input.propertyType,
          cautionText: input.cautionText,
          displayOrder: input.displayOrder,
          placeholderText: input.placeholderText,
        },
      });

      if (response?.updateProperty) {
        return response.updateProperty;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * プロパティを削除します.
   * @param input プロパティの削除情報
   * @returns 削除したプロパティ
   */
  public async deleteProperty(input: Property) {
    try {
      const response = await sdk.deleteProperty({
        deletePropertyInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteProperty) {
        return response.deleteProperty;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new PropertyService();
