
import { Order, OrderState } from "@/graphql/client";
import { Component, Ref, Vue } from "vue-property-decorator";
import OrderStatusChageDialog from "@/components/organisms/order/OrderStatusChageDialog.vue";
import { NotificationState } from "@/store/modules/notification";

@Component({
  components: {
    OrderStatusChageDialog,
  },
})
export default class MixinOrderAction extends Vue {
  @Ref() readonly statusChangeDialog!: OrderStatusChageDialog;
  //---------------------------
  // methods
  //---------------------------
  /**
   * 注文ステータスを変更
   */
  transitionStatus(
    item: Order,
    action: {
      value: string;
      label: string;
      beforeOrderStatus: OrderState[];
    }
  ): void {
    var targetItem = Object.assign({}, item);
    this.statusChangeDialog.open(targetItem, action);
  }

  /**
   * 注文ステータス変更ダイアログの更新成功時.
   */
  onStatusChangeSuccess(uodated: Order): void {
    this.statusChangeDialog.close();
    NotificationState.notifySuccess("注文ステータスを変更しました！");
  }

  /**
   * 注文ステータス変更NG
   */
  onStatusChangeError(message: string): void {
    this.statusChangeDialog.close();
    NotificationState.notifyError(message);
  }
}
